import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import IDALayout from "../../../components/layouts/layout-ida"
import Seo from "../../../components/seo"

import AdverseChartLg from "../../../images/ida/safety/GI-adverse-events-lg@2x.png"
import AdverseChartSm from "../../../images/ida/safety/GI-adverse-events-sm@2x.png"

import EopCalloutCards from "../../../components/shared/generic/eop-callout-cards"
import akbcares from "../../../images/AkebiaCares-logo.png"
import CalloutIcon1 from "../../../images/icons/icon-callout-ida-efficacy.png"

const IdaSafety = ({ location }) => (
    <IDALayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1408 (v4.0)"
        jobCodeDate="09/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.; 2021.
            </>,
            <>
                Fishbane S, Block GA, Loram L, et al. Effects of ferric citrate
                in patients with nondialysis-dependent CKD and iron deficiency
                anemia. <em>J Am Soc Nephrol.</em> 2017;28(6):1851-1858.
            </>,
            <>Data on File 13, Akebia Therapeutics, Inc.</>,
            <>
                Chertow GM, Block GA, Neylan JF, Pergola PE, Uhlig K, Fishbane
                S. Safety and efficacy of ferric citrate in patients with
                nondialysis-dependent chronic kidney disease. <em>PLoS One.</em>{" "}
                2017;12(11): e0188712. doi: 0.1371/journal.pone.0188712.
            </>,
            <>
                Supplement to: Fishbane S, Block GA, Loram L, et al. Effects of
                ferric citrate in patients with nondialysis-dependent CKD and
                iron deficiency anemia. <em>J Am Soc Nephrol.</em>{" "}
                2017;28(6):1851-1858.
            </>,
            <>Data on File 16, Akebia Therapeutics, Inc.</>,
            <>Data on File 14, Akebia Therapeutics, Inc.</>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/iron-deficiency-anemia/safety/adverse-reactions"
            />
            <body id="ida-hcp-safety" className="ida" />
        </Helmet>

        <Seo
            title="Adverse Reactions"
            description="Explore the safety and tolerability profile for AURYXIA (ferric citrate) tablets."
        />

        <section id="hero" className="center">
            <div className="flex-row">
                <div>
                    <h1>AURYXIA has a proven safety&nbsp;profile</h1>

                    <p>
                        Explore the safety and tolerability profile for AURYXIA
                        to see if it is right for your adult&nbsp;patients.
                        <sup>1</sup>
                    </p>
                </div>
            </div>
        </section>

        <section id="safety-cards-1" className="safety-cards flood-ida col-10">
            <div className="flex-row">
                <div
                    data-sal="slide-up"
                    data-sal-duration="600"
                    data-sal-delay="200"
                    data-sal-ease="ease-out-expo"
                >
                    <div>
                        <h2 className="ida">
                            Safety and tolerability profile evaluated in a
                            pivotal clinical&nbsp;trial<sup>1</sup>
                        </h2>

                        <div className="table-wrap">
                            <h4>
                                AURYXIA had a similar tolerability profile as
                                placebo during the{" "}
                                <span className="nowrap">16-week</span>{" "}
                                randomized&nbsp;period<sup>1,2</sup>
                            </h4>
                            <table>
                                <tbody>
                                    <tr>
                                        <th rowSpan="2">
                                            Discontinuation rates due to adverse
                                            reactions<sup>1</sup>
                                        </th>
                                        <th>
                                            AURYXIA
                                            <br />
                                            (N=117)
                                        </th>
                                        <th>
                                            PLACEBO
                                            <br />
                                            (N=116)
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>12 (10%)</td>
                                        <td>10 (9%)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <ul>
                            <li>
                                The most common adverse reaction leading to
                                discontinuation of AURYXIA was diarrhea at 2.6%
                                <sup>1</sup>
                            </li>
                            <li>
                                The most common adverse event was diarrhea
                                (20.5%), the majority of which was mild to
                                moderate in severity<sup>2,3</sup>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section id="safety-cards-2" className="safety-cards col-10">
            <div className="flex-row">
                <div
                    data-sal="slide-up"
                    data-sal-duration="600"
                    data-sal-delay="200"
                    data-sal-ease="ease-out-expo"
                >
                    <div>
                        <h2 className="ida">
                            Safety and tolerability profile evaluated in two
                            clinical trials<sup>1</sup>*
                        </h2>

                        <div className="table-wrap">
                            <table className="clinical-trial">
                                <tbody>
                                    <tr>
                                        <th width="300">
                                            Adverse reactions reported in at
                                            least 5% of patients treated with
                                            AURYXIA
                                        </th>
                                        <th width="156">
                                            AURYXIA %<br />
                                            (N=190)
                                        </th>
                                        <th width="148">
                                            Placebo %<br />
                                            (N=188)
                                        </th>
                                    </tr>
                                    <tr>
                                        <td width="300">
                                            Any Adverse Reaction
                                        </td>
                                        <td width="156">75</td>
                                        <td width="148">62</td>
                                    </tr>
                                    <tr>
                                        <td width="300" colSpan="3">
                                            Metabolism and Nutrition Disorders
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="300">Hyperkalemia</td>
                                        <td width="156">5</td>
                                        <td width="148">3</td>
                                    </tr>
                                    <tr>
                                        <td width="300" colSpan="3">
                                            Gastrointestinal Disorders
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="300">Discolored Feces</td>
                                        <td width="156">22</td>
                                        <td width="148">0</td>
                                    </tr>
                                    <tr>
                                        <td width="300">Diarrhea</td>
                                        <td width="156">21</td>
                                        <td width="148">12</td>
                                    </tr>
                                    <tr>
                                        <td width="300">Constipation</td>
                                        <td width="156">18</td>
                                        <td width="148">10</td>
                                    </tr>
                                    <tr>
                                        <td width="300">Nausea</td>
                                        <td width="156">10</td>
                                        <td width="148">4</td>
                                    </tr>
                                    <tr>
                                        <td width="300">Abdominal Pain</td>
                                        <td width="156">5</td>
                                        <td width="148">2</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p>
                            *Data from a study of 117 patients treated with
                            AURYXIA and 116 patients treated with placebo in a
                            16-week, randomized, double-blind period and a study
                            of 75 patients treated with AURYXIA and 73 treated
                            with placebo in a 12-week randomized double-blind
                            period. Dosage regimens in these trials ranged from
                            210 mg to 2,520 mg of ferric iron per day,
                            equivalent to 1 to 12 tablets of AURYXIA.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section id="safety-cards-3" className="safety-cards flood-ida col-10">
            <div className="flex-row">
                <div
                    data-sal="slide-up"
                    data-sal-duration="600"
                    data-sal-delay="200"
                    data-sal-ease="ease-out-expo"
                >
                    <div>
                        <h2 className="ida incidence_txt">
                            The incidence of GI-related adverse events declined
                            over&nbsp;time
                        </h2>
                        <p>
                            Pooled analysis of GI AEs over 16 weeks<sup>4</sup>
                        </p>
                        <figure>
                            <figcaption>
                                <p className="square auryxia">
                                    {" "}
                                    AURYXIA (N=190)
                                </p>
                                <p className="square placebo">
                                    {" "}
                                    PLACEBO (N=188)
                                </p>
                            </figcaption>
                            <img
                                src={AdverseChartLg}
                                alt=""
                                className="large-only"
                            />
                            <img
                                src={AdverseChartSm}
                                alt=""
                                className="small-only"
                            />
                        </figure>
                    </div>
                </div>
            </div>
        </section>

        <section id="safety-cards-4" className="safety-cards col-10">
            <div className="flex-row">
                <div
                    data-sal="slide-up"
                    data-sal-duration="600"
                    data-sal-delay="200"
                    data-sal-ease="ease-out-expo"
                >
                    <div>
                        <h2 className="ida">
                            Safety and tolerability profile evaluated in a
                            pivotal clinical&nbsp;trial<sup>1</sup>
                        </h2>

                        <div className="table-wrap">
                            <h4>
                                Similar incidence of severe adverse events
                                (SAEs) between AURYXIA and placebo groups during
                                the 16-week randomized&nbsp;period<sup>1,2</sup>
                            </h4>
                            <p>
                                No individual SAEs were observed in more than 5%
                                of patients treated with&nbsp;AURYXIA.
                            </p>
                            <table>
                                <tbody>
                                    <tr>
                                        <th rowSpan="2">
                                            SAEs during the 16-week randomized
                                            period<sup>2</sup>
                                        </th>
                                        <th>
                                            AURYXIA
                                            <br />
                                            (N=117)
                                        </th>
                                        <th>
                                            PLACEBO
                                            <br />
                                            (N=116)
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>14 (12.0%)</td>
                                        <td>13 (11.2%)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <EopCalloutCards
            siteAudience="ida"
            callOneClass={"highlight"}
            callOneLink={"/iron-deficiency-anemia/efficacy/"}
            callOneContent={
                <>
                    <img src={CalloutIcon1} alt="" width="98" height="98" />
                    <h3>See what AURYXIA can do for your patients</h3>
                    <p>
                        AURYXIA increased Hb and iron parameters (TSAT and
                        ferritin) without IV iron or ESA use in a 16-week
                        pivotal trial.<sup>1,2,6,7</sup>
                    </p>
                    <button className="cta ida">
                        <span>EXAMINE EFFICACY</span>
                    </button>
                    <p className="footnote center">
                        IV iron use and ESA use were not allowed per trial
                        design and exclusion criteria.<sup>1</sup>
                    </p>
                </>
            }
            callTwoClass={"akebia-cares"}
            callTwoLink={"https://www.akebiacareshcp.com/"}
            callTwoContent={
                <>
                    <img src={akbcares} alt="AkebiaCares" />
                    <h3>
                        Your partner in helping patients access the medication
                        they need
                    </h3>
                    <p>
                        AkebiaCares supports your patients with information and
                        resources that can help people start and stay on
                        AURYXIA.
                    </p>
                    <button className="cta akb">
                        <span>FIND SUPPORT OPTIONS</span>
                    </button>
                </>
            }
        />

        <section id="interested-slab" className="col-6 center">
            <div
                className="flex-row"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h3>You may also be interested&nbsp;in: </h3>
                    <p>
                        <Link
                            to="/iron-deficiency-anemia/safety/pharmacodynamics/"
                            className="gradient-link ida"
                        >
                            SAFETY: PHARMACODYNAMICS
                        </Link>
                    </p>
                </div>
            </div>
        </section>

        <section id="trial-design" className="flood-ida col-8">
            <div calss="flex-row">
                <div>
                    <p>
                        <strong>
                            Trial design<sup>1</sup>
                        </strong>
                    </p>

                    <p>
                        In a 24-week study consisting of a 16-week, randomized,
                        double-blind, placebo-controlled efficacy period
                        followed by an 8-week, open-label safety extension
                        period, this trial evaluated the efficacy and safety of
                        AURYXIA for the treatment of iron deficiency anemia in
                        adult patients with CKD not on dialysis. Patients who
                        were intolerant of or have had an inadequate therapeutic
                        response to oral iron supplements, with hemoglobin
                        &#x2265;9.0 g/dL and &#x2264;11.5 g/dL, serum ferritin
                        &#x2264;200 ng/mL, and TSAT &#x2264;25% were enrolled.
                        Patients were randomized to treatment with either
                        AURYXIA (n=117) or placebo (n=117).
                    </p>
                    <p>
                        The primary endpoint was the proportion of patients
                        achieving a &#x2265;1.0 g/dL increase in hemoglobin at
                        any time point during the 16-week efficacy period. Use
                        of oral iron, IV iron, or ESAs was not permitted at any
                        time during the trial.{" "}
                    </p>

                    <p className="footnote">
                        AE=adverse event; CKD=chronic kidney disease;
                        GI=gastrointestinal; SAE=serious adverse event.
                    </p>
                </div>
            </div>
        </section>
    </IDALayout>
)

export default IdaSafety
